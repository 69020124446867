import { GetServerSidePropsContext } from "next";
import { API, Http } from "services/http/http";
import { paramsObjectToQueryString } from "utils/generic-utils";

export const createVendor = (payload: any) => Http.post(`/store`, payload);

export const createProduct = (payload: any) => Http.post(`/product`, payload);

export const getStore = () => Http.get(`/user/store`);

export const getProducts = (payload?: any) =>
   API({
      method: "GET",
      url: `/product${paramsObjectToQueryString(payload)}`,
   });

export const fetchSingleProduct = (token, { queryKey }) =>
   Http.get(`/product/${queryKey[1]}/details`, {
      headers: {
         authorization: token,
      },
   });

export const deleteProduct = (id: string) => Http.delete(`/product/${id}`);

export const updateProduct = (id: string, payload: any) => Http.put(`/product/${id}`, payload);

export const fetchProductsForUsers = () => Http.get(`/product/all`);

export const fetchDeliveryAddress = () => Http.get(`/delivery-address`);

export const createDeliveryAddress = (payload: any) => Http.post(`/delivery-address`, payload);

export const fetchTrendingProducts = () => Http.get(`/product/trending`);

export const fetchBestDeals = () => Http.get(`/product/best-deals`);

export const fetchSingleMarketPlaceProduct = (token, { queryKey }) =>
   Http.get(`/product/${queryKey[1]}`, {
      headers: {
         authorization: token,
      },
   });

export const saveProductsToCart = (payload: any) => Http.post(`/cart`, payload);

export const fetchUserCart = (id) => Http.get(`/cart/${id}`);

export const setDefaultDeliveryAddress = (id) => Http.patch(`/delivery-address/${id}/default`);

export const editDeliveryAddress = (payload: any, id) =>
   Http.put(`/delivery-address/${id}`, payload);

export const deleteDeliveryAddress = (id) => Http.delete(`/delivery-address/${id}`);
export const fetchLoggedInVendor = () => Http.get(`/user/store`);
export const updateVendorProfile = (id: string, payload: any) => Http.put(`/store/${id}`, payload);

export const fetchVendors = (payload?: any) =>
   API({
      method: "GET",
      url: `/store${paramsObjectToQueryString(payload)}`,
   });
export const fetchVendorOrders = (payload?: any) =>
   API({
      method: "GET",
      url: `/order${paramsObjectToQueryString(payload)}`,
   });
export const fetchVendorAdmin = (id: string) => Http.get(`/store/${id}`);
export const fetchVendorSingleProductAdmin = (id) => Http.get(`/product/${id}/details`);
export const verifyVendorAdmin = (id: string, payload) =>
   Http.patch(
      `/store/${id}/verify
`,
      payload
   );
export const fetchCategoriesAdmin = () => Http.get(`/product-category/`);
export const editCategoryAdmin = (payload: { id: string; name: string }) =>
   Http.put(`/product-category/${payload.id}`, { name: payload.name });
export const addCategoryAdmin = (payload) => Http.post(`/product-category`, payload);
export const deleteCategoryAdmin = (id) => Http.delete(`/product-category/${id}`);

export const makeOrder = (payload) => Http.post(`/order`, payload);

export const fetchOrders = (payload) => Http.get(`/order/${paramsObjectToQueryString(payload)}`);

export const fetchProductsSearch = (
   payload: Record<string, any>,
   ctx?: GetServerSidePropsContext
) =>
   API(
      {
         method: "GET",
         url: `/product/all${paramsObjectToQueryString(payload)}`,
      },
      ctx
   );
export const vendorAcceptOrder = (id) => Http.patch(`/order/${id}/accept`);
export const vendorRejectOrder = (id) => Http.patch(`/order/${id}/reject`);

export const customerReview = (id: string, payload: any) =>
   Http.post(`/product/${id}/review`, payload);

export const fetchVendorProducts = (id) => Http.get(`/product/all?storeId=${id}`);

export const fetchProductReviews = (id) => Http.get(`/product/${id}/review`);

export const fetchProductReviewsSummary = (id) => Http.get(`/product/${id}/review-summary`);

export const fetchProductReviewsPaginated = (id: string, payload: any) =>
   Http.get(`/product/${id}/review${paramsObjectToQueryString(payload)}`);

export const unVerifyVendorAdmin = (id: string) =>
   Http.patch(
      `/store/${id}/verify
`
   );

export const editVendorCommission = (id: string, payload) =>
   Http.patch(
      `/store/${id}/commission
`,
      payload
   );

export const confirmDelivery = (id: string, payload) =>
   Http.patch(
      `/order/${id}/complete
`,
      payload
   );
