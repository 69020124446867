import { css } from "styled-components";

export const fontWeights = {
   regular: "400",
   medium: "500",
   semiBold: "600",
   bold: "700",
   extraBold: "800",
   black: "900",
};

export const fontFamily = css`
   @font-face {
      font-family: "Paper notes";
      src: url("/fonts/Papernotes.ttf");
      font-weight: 200;
   }
   @font-face {
      font-family: "Paper notes";
      src: url("/fonts/Papernotes.ttf");
      font-weight: 400;
   }
   @font-face {
      font-family: "Paper notes";
      src: url("/fonts/Papernotes.ttf");
      font-weight: 500;
   }

   @font-face {
      font-family: "Paper notes";
      src: url("/fonts/Papernotes-Bold.ttf");
      font-weight: 600;
   }
   @font-face {
      font-family: "Paper notes";
      src: url("/fonts/Papernotes-Sketch.ttf");
      font-weight: 800;
   }
`;
