/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDebouncedCallback } from "use-debounce";
import { useNotify } from "hooks";
import { useSecondRunEffect } from "hooks/useSecondRunEffect";
import { fetchUserCart, saveProductsToCart } from "services/marketplace";

// Create a context
const CartContext = createContext<object[]>([]);

export function CartProvider({ children }) {
   const queryClient = useQueryClient();
   const [cartItems, setCartItems] = useState<object[]>([]);
   const [cartId, setCartId] = useState<string>("");
   const { notify } = useNotify();

   const debounced = useDebouncedCallback(saveCart, 500);

   const { data, refetch, isLoading } = useQuery("user-cart", () => fetchUserCart(cartId));

   useEffect(() => {
      if (data) {
         setCartItems(data?.data?.data?.items);
      }
   }, [data]);

   useSecondRunEffect(debounced, [cartItems]);

   const addItemToCart = (productId) => {
      const newItem = {
         productId: productId,
         quantity: 1,
      };

      setCartItems((prevCartItems) => prevCartItems.concat(newItem));
   };

   const increaseQuantity = (productId) => {
      const updatedCartItems = cartItems?.map((item: any) => {
         if (item?.productId === productId) {
            return {
               ...item,
               quantity: item?.quantity + 1,
            };
         }
         return item;
      });

      setCartItems(updatedCartItems);
   };

   const decreaseQuantity = (productId) => {
      const updatedCartItems = cartItems
         .map((item: any) => {
            if (item.productId === productId) {
               const updatedQuantity = item.quantity - 1;

               // Remove the item from the cart if the quantity becomes zero
               if (updatedQuantity <= 0) {
                  return null;
               }

               return {
                  ...item,
                  quantity: updatedQuantity,
               };
            }
            return item;
         })
         .filter((item) => item !== null); // Remove null entries

      setCartItems(updatedCartItems);
   };

   const { mutateAsync } = useMutation((cartItems) => saveProductsToCart({ item: cartItems }), {
      onSuccess: () => {
         queryClient.invalidateQueries("user-cart");
      },
   });

   async function saveCart() {
      const extractedData: any = cartItems?.map((item: any) => ({
         productId: item.productId,
         quantity: item.quantity,
      }));

      try {
         const response: any = await mutateAsync(extractedData);
         const newCartId = response?.data?.data?.id;
         setCartId(newCartId);
         refetch();
      } catch (err: any) {
         notify({
            message: err.result.message || err.result.custom_message,
            variant: "error",
         });
      }
   }

   const isProductInCart = useMemo(
      () => (productId) => cartItems?.some((item: any) => item.productId === productId),
      [cartItems]
   );

   const getQuantityByProductId = useMemo(
      () => (productId) => {
         const item: any = cartItems?.find((item: any) => item.productId === productId);
         return item ? item.quantity : 0;
      },
      [cartItems]
   );

   function deleteItemByProductId(itemArray, productIdToDelete) {
      const updatedArray = itemArray?.filter((item: any) => item?.productId !== productIdToDelete);
      setCartItems(updatedArray);
   }

   function calculateTotalCount(itemArray, type) {
      let totalProductPrice = 0;
      let totalDiscount = 0;

      if (type === "price") {
         for (const item of itemArray) {
            if (item?.productDiscountPrice > 0) {
               const discountedPrice = item?.productPrice - item?.productDiscountPrice;
               totalProductPrice += discountedPrice * item?.quantity;
               totalDiscount += item?.productDiscountPrice * item?.quantity; // Accumulate the discount
            } else {
               totalProductPrice += item?.productPrice * item?.quantity;
            }
         }
      } else if (type === "count") {
         for (const item of itemArray) {
            totalProductPrice += item?.quantity;
         }
      } else {
         for (const item of itemArray) {
            totalProductPrice += item?.cost;
         }
      }

      return { totalProductPrice, totalDiscount };
   }

   function CalculateDiscount(discountAmount, price) {
      const totalPrice = price - discountAmount;
      return totalPrice;
   }

   // Create an object with the cart state and functions
   const cartState: any = {
      cartItems,
      addItemToCart,
      increaseQuantity,
      decreaseQuantity,
      isProductInCart,
      saveCart,
      getQuantityByProductId,
      cartId,
      deleteItemByProductId,
      calculateTotalCount,
      isLoading,
      refetch,
      CalculateDiscount,
   };

   return <CartContext.Provider value={cartState}>{children}</CartContext.Provider>;
}

// Create a custom hook to consume the cart context
export function useCart() {
   return useContext(CartContext);
}
