import { useSnackbar as useSnack, VariantType } from "notistack";

interface INotifyProps {
   message: string;
   duration: number | string | any;
   persist?: boolean;
   variant?: VariantType;
   key?: any;
}

const useNotify = () => {
   const { enqueueSnackbar, closeSnackbar } = useSnack();

   function notify({ message, duration, variant, persist, key }: INotifyProps | any) {
      enqueueSnackbar(message, { autoHideDuration: duration, variant, persist, key });
   }

   function closeNotify(key: string | number) {
      closeSnackbar(key);
   }

   return { notify, closeNotify };
};

export { useNotify };
