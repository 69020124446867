import { useReducer, createContext } from "react";

interface ToogleSidebarProps {
   toggle: boolean;
}

const initialState = {
   toggle: false,
};

interface Action {
   type: "TOGGLE_SIDEBAR";
}

const setToggleSidebarAction = () => ({
   type: "TOGGLE_SIDEBAR",
});

type toggleSidebarProviderProps = { children: React.ReactNode };

const ToggleSidebarContext = createContext<{
   state: ToogleSidebarProps;
   dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

function toggleSidebarReducer(state: ToogleSidebarProps, action: Action) {
   switch (action.type) {
      case "TOGGLE_SIDEBAR": {
         return {
            ...state,
            toggle: !state.toggle,
         };
      }
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function ToggleSidebarContextProvider({ children }: toggleSidebarProviderProps) {
   const [state, dispatch] = useReducer(toggleSidebarReducer, initialState);

   return (
      <ToggleSidebarContext.Provider value={{ state, dispatch }}>
         {children}
      </ToggleSidebarContext.Provider>
   );
}

export { ToggleSidebarContextProvider, ToggleSidebarContext, setToggleSidebarAction };
