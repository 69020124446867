/* eslint-disable import/no-unresolved */
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import getConfig from "next/config";
import { getSession } from "utils/auth";
import { isClient } from "utils/generic-utils";
import { getToken } from "utils/storage";

const {
   publicRuntimeConfig: { API_BASE_URL },
} = getConfig();

const Http = axios.create({
   baseURL: `${API_BASE_URL}`,
   headers: {
      "Content-Type": "application/json",
      "Accept-Encoding": "gzip",
   },
});

export interface IRequest extends AxiosResponse {
   ok?: boolean;
   result?: {
      data: any;
   };
   config: AxiosRequestConfig;
}

const requestHandler = async (request: AxiosRequestConfig) => {
   if (isClient) {
      request.headers = {
         Authorization: `Bearer ${getToken()}`,
      };

      return request;
   }

   return request;
};

const responseHandler = (res: IRequest) => {
   const response = { ...res };

   response.ok = true;
   response.result = response?.data;

   delete response.request;
   // @ts-ignore
   delete response.config;
   delete response.headers;

   return Promise.resolve(response);
};

const errorHandler = (error: any) => {
   const responseError = { ...error };
   let message;

   responseError.result = error.response?.data;
   responseError.ok = false;
   delete responseError.request;
   delete responseError.config;

   if (responseError.response === undefined) {
      responseError.result = {
         custom_message: "Network Error!. Kindly Try again",
      };

      return Promise.reject(responseError);
   }

   switch (responseError.response.status) {
      case 400:
         message = "Bad Request";
         break;
      case 401:
         message = "You're not Authenticated. Kindly Login";
         break;
      case 403:
         message = "UnAuthorised User";
         break;
      case 404:
         message = "Resource not found";
         break;
      case 409:
         message = "Duplicate data";
         break;
      case 413:
         message = "Payload too large";
         break;
      case 500:
         message = "Internal server error";
         break;
      default:
         message = "Network Error. Kindly Try again.";
   }

   responseError.result = {
      custom_message: message,
      ...responseError.result,
   };

   return Promise.reject(responseError);
};

Http.interceptors.response.use(
   (response) => responseHandler(response),
   (error) => errorHandler(error)
);

const API = ({ method, url }: { method: Method; url: string }, ctx?: any, token?: string) => {
   return Http({
      method,
      baseURL: `${API_BASE_URL}`,
      headers: {
         Authorization: getToken() || getSession(ctx).token || token,
      },
      url,
   });
};

Http.interceptors.request.use(
   (request) => requestHandler(request),
   (error) => errorHandler(error)
);

Http.defaults.headers.common = `Bearer ${getToken()}`;

export { Http, API };
