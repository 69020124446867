import { css } from "styled-components";

export const variables = css`
   :root {
      /* Colors */
      --color-white: #fff;
      --color-black: #000;
      --color-red: red;
      --color-blue: #3578e5;
      --color-grey: #1c1c39;
      --color-transparent: transparent;

      /* Font Sizes */
      --font-size-small: 1.4rem;
      --font-size-medium: 1.6rem;

      --bottom-notch-height: env(safe-area-inset-bottom);
      --min-tap-target-height: 4.8rem;

      $xsmall: "468px",
      $small: "768px",
      $medium: "992px",
      $large: "1200px",
      $xlarge: "1400px",

      @media (min-width: 1024px) {
         --font-size-small: 1.7rem;
         --font-size-medium: 1.9rem;

         --min-tap-target-height: 3.2rem;
      }
   }
`;
