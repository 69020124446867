import { css } from "styled-components";

export const fontWeights = {
   regular: "400",
   medium: "500",
   semiBold: "600",
   bold: "700",
   extraBold: "800",
   black: "900",
};

export const fontFamily = css`
   @font-face {
      font-family: "Colour Sans";
      src: url("/fonts/coloursans-bold-webfont.woff2.ttf");
      font-weight: 200;
   }
   @font-face {
      font-family: "Colour Sans";
      src: url("/fonts/coloursans-bold-webfont.woff2.ttf");
      font-weight: 300;
   }
   @font-face {
      font-family: "Colour Sans";
      src: url("/fonts/coloursans-bold-webfont.woff2.ttf");
      font-weight: 400;
   }
   @font-face {
      font-family: "Colour Sans";
      src: url("/fonts/coloursans-bold-webfont.woff2.ttf");
      font-weight: 500;
   }

   @font-face {
      font-family: "Colour Sans";
      src: url("/fonts/coloursans-bold-webfont.woff2.ttf");
      font-weight: 600;
   }
`;
