import { css } from "styled-components";

export const override = css`
   .slick-list {
      margin: 0 -10px;
   }

   .slick-slide > div {
      padding: 0 10px;
   }

   .slick-prev {
      top: 50%;
      left: -40px;
      z-index: 100;
      opacity: 1;
   }

   .slick-next {
      top: 50%;
      right: -30px;
      z-index: 100;
      opacity: 1;
   }

   .slick-prev:before,
   .slick-next:before {
      font-size: 30px;
      color: #3577e5;
      font-weight: 100;
      opacity: 1;
   }

   .slick-list .slick-track {
      min-width: 1200px !important;
   }

   .snackbar {
      font-size: 1.4rem !important;
      font-family: "Mark OT", sans-serif !important;
   }

   .react-confirm-alert-overlay {
      background-color: rgb(84 83 83 / 90%);
   }

   .editor-wrapper {
      img {
         max-width: 100%;
         height: auto;
      }
   }
`;
