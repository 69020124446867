import { AxiosRequestConfig } from "axios";
import cookies from "next-cookies";
import router from "next/router";
import { isTokenValid } from "./api/token-validity";
import {
   clearStorage,
   clearToken,
   getToken,
   getUserInfoFromStorage,
   TOKEN,
   USER_DATA,
} from "./storage";
const isClient = typeof window !== "undefined";

interface UserData {
   firstName: string;
   lastName: string;
   email: string;
   partnerId?: number;
   type: "parent" | "sch_admin" | "super_admin" | "loan_manager" | "study_abroad_partner";
   studyAbroadSubscriptionType: string;
}

export const getSession = (ctx?: any) => {
   let inSession: boolean | undefined;
   let user: UserData;
   let token: string | undefined;

   function logout() {
      clearToken();
      clearStorage();
      typeof window !== "undefined" && router.push("/signin?redirect=true");
   }

   if (isClient) {
      token = getToken();
      inSession = isTokenValid(token as string);
      if (!inSession) {
         clearStorage();
      }
      user = getUserInfoFromStorage();
   } else {
      const myCookie = cookies(ctx || "");
      token = myCookie[TOKEN] as string;
      inSession = isTokenValid(token);
      user = myCookie[USER_DATA] as any;
   }

   return {
      inSession,
      user,
      logout,
      token: `Bearer ${token}`,
      isAdmin: user?.type === "sch_admin" || user?.type === "super_admin",
   };
};

export const requestHandler = async (request: AxiosRequestConfig, token: string) => {
   request.headers = {
      Authorization: `Bearer ${getToken() || token}`,
   };
   return request;
};
