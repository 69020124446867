import { DefaultTheme } from "styled-components";
import { ActivatedStyles } from "theme/activatedstyle";
import { ArtFestStyles } from "theme/artstyle";
import { customTheme } from "theme/custom-theme";
import { GlobalStyles } from "theme/global";
import { colors } from "./color";
import { mediaQueries } from "./custom-queries";
import { fontWeights } from "./fontWeight";

const theme: DefaultTheme = {
   colors,
   fontWeights,
   mediaQueries,
};

export { theme, GlobalStyles, customTheme, ArtFestStyles, ActivatedStyles };
