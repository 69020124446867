/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

// avoid running effect on mount
export const useSecondRunEffect = (callback: () => void, deps: any[]) => {
   const isFirstRun = useRef(true);

   useEffect(() => {
      if (isFirstRun.current) {
         isFirstRun.current = false;
         return;
      }
      callback();
   }, deps);
};
