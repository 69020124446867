import { createGlobalStyle } from "styled-components";
import { fontFamily } from "./artfont";

const ArtFestStyles = createGlobalStyle`
   ${fontFamily};
   
   /**
   * We find it much easier to reason with border-box as the default box-sizing.
   */
   *,
   *::before,
   *::after {
      box-sizing: border-box;
   }

   html {
      /**
    * The default font size for browsers is 16px.
    * We apply a size of 62.5% so that, by default, all math with rems will use
    * 10px as a base instead of 16px.
    */
      font-size: 62.5%;
      overflow-x: hidden;
   scroll-behavior: smooth;

   }

   body {
      -webkit-font-smoothing: antialiased;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: -0.03rem;
      line-height: 2.5rem;
      background:#fdfdfd;
      /* overflow-x: hidden; */
      font-family: "Mark OT", sans-serif;
      margin: 0;
      padding: 0;
   }

   h1 {
      font-family: 'Colour Sans';
   }

   h2 {
      font-family: 'Colour Sans';
   }

 
   u,
   a {
      @supports (text-underline-offset: 0.4rem) {
         text-underline-offset: 0.4rem;
         padding-bottom: 0.1rem;
      }
      @supports not (text-underline-offset: 0.4rem) {
         text-underline-position: under;
      }
   }

   .link {
      color: inherit;
      text-decoration: none;
      outline: none;
   }

`;

export { ArtFestStyles };
