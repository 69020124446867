/* eslint-disable no-restricted-syntax */
export function paramsObjectToQueryString(payload) {
   const trimmedPayload = trimObject(payload);
   const paramPayloadToArr = Object?.keys(trimmedPayload);
   if (!trimmedPayload || paramPayloadToArr.length < 1) return "";
   return paramPayloadToArr.reduce((acc, element, _index, array) => {
      acc = `${array[0] === element ? "?" : ""}${acc}${element}=${trimmedPayload[element]}${
         array[array.length - 1] !== element ? "&" : ""
      }`;

      return acc;
   }, "");
}

export const isEmpty = (value) =>
   value === undefined ||
   value === null ||
   (typeof value === "object" && Object.keys(value).length === 0) ||
   (typeof value === "string" && value.trim().length === 0) ||
   (typeof value === "object" && value.toString().length === 0);

export const trimObject = (obj) => {
   for (const propName in obj) {
      if (isEmpty(obj[propName])) {
         delete obj[propName];
      }
   }

   return obj;
};

export const trimObjectToNull = (obj) => {
   for (const propName in obj) {
      if (isEmpty(obj[propName])) {
         obj[propName] = null;
      }
   }

   return obj;
};

export function omit(obj, key) {
   const { [key]: omitted, ...rest } = obj;
   return rest;
}

export const isClient = typeof window !== "undefined";

export const formatCurrency = (amount: number, currencyCode: string) => {
   // Round the amount to the nearest integer
   let roundedAmount = Math.ceil(amount);

   // Format the rounded amount as currency
   let formattedAmount = Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: currencyCode,
   }).format(roundedAmount);

   // Remove extra two zeros
   formattedAmount = formattedAmount.replace(/\.00$/, "");

   return formattedAmount;
};

export function removeLastTwoZeros(number) {
   // Convert number to string
   let numberAsString = number.toString();

   // Check if the number has a decimal point
   if (numberAsString.includes(".")) {
      // Split the string at the decimal point
      let parts = numberAsString.split(".");

      // If the fractional part has two or more digits, remove the last two zeros
      if (parts[1].length >= 2) {
         parts[1] = parts[1].slice(0, -2);
      }

      // Join the parts back together
      numberAsString = parts.join(".");
   }

   // Parse the string back to a number
   return parseFloat(numberAsString);
}

export function getLastDigits(inputString, number) {
   // Check if the input string has at least 8 characters
   if (inputString?.length >= number) {
      // Use the slice method to get the last 8 characters
      return inputString?.slice(-number);
   } else {
      // If the input string has fewer than 8 characters, return it as is
      return inputString;
   }
}

export function commafy(numb: number | string, addCurrency?: boolean) {
   if (addCurrency) {
      return `₦${numb?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
   }
   return numb?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
