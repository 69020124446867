import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { AppProps } from "next/app";
import getConfig from "next/config";
import Error from "next/error";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";
import { ThemeProvider } from "styled-components";
import TawkTo from "tawkto-react";
import FormProvider from "context/application-data/application";
import UkFormProvider from "context/application-data/uk-application";
import { CartProvider } from "context/cart-data/cart";
import { FiltersContextProvider } from "context/school-filters";
import ToggleFilterProvider from "context/school-filters/toggle-filter";
import ToggleModalFilterProvider from "context/school-filters/toggle-filter-modal";
import { ToggleSidebarContextProvider } from "context/toggle-sidebar";
import { GlobalStyles, theme } from "theme/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-quill/dist/quill.snow.css";

const {
   publicRuntimeConfig: {
      NEXT_PUBLIC_GOOGLE_ANALYTICS,
      EDUSKO_TAWKTO_PROPERTY_ID,
      EDUSKO_TAWKTO_TAWK_ID,
   },
} = getConfig();

function MyApp({ Component, pageProps }: AppProps) {
   const [queryCLient] = useState(
      () =>
         new QueryClient({
            defaultOptions: {
               queries: {
                  refetchOnWindowFocus: false,
                  refetchOnReconnect: false,
                  retry: false,
               },
            },
         })
   );

   useEffect(() => {
      new TawkTo(EDUSKO_TAWKTO_PROPERTY_ID, EDUSKO_TAWKTO_TAWK_ID);
   }, []);
   TawkTo.hide;
   useEffect(() => {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
   }, []);

   if (pageProps.error) {
      return (
         <Error
            statusCode={pageProps.errorStatus}
            title={pageProps.errorMsg || "An Error Just Occurred"}
         />
      );
   }

   return (
      <>
         <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
         />

         <Script strategy="lazyOnload">
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                  page_path: window.location.pathname,
                  });
               `}
         </Script>
         <QueryClientProvider client={queryCLient}>
            <GlobalStyles />
            <Hydrate state={pageProps.dehydratedState}>
               <ThemeProvider theme={theme}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <SnackbarProvider maxSnack={1} className="snackbar">
                     <ToggleModalFilterProvider>
                        <ToggleFilterProvider>
                           <ToggleSidebarContextProvider>
                              <AnimatePresence
                                 exitBeforeEnter
                                 initial={false}
                                 onExitComplete={() => window.scrollTo(0, 0)}
                              >
                                 <FiltersContextProvider>
                                    <CartProvider>
                                       <FormProvider>
                                          <UkFormProvider>
                                             <div style={{ zIndex: "3000" }}>
                                                <NextNProgress
                                                   color="#3578e5"
                                                   startPosition={0.4}
                                                   stopDelayMs={200}
                                                   height={3.2}
                                                   showOnShallow={false}
                                                />
                                             </div>
                                             <Component {...pageProps} />
                                          </UkFormProvider>
                                       </FormProvider>
                                    </CartProvider>
                                 </FiltersContextProvider>
                              </AnimatePresence>
                           </ToggleSidebarContextProvider>
                        </ToggleFilterProvider>
                     </ToggleModalFilterProvider>
                  </SnackbarProvider>
               </ThemeProvider>
            </Hydrate>
         </QueryClientProvider>
      </>
   );
}

export default MyApp;
