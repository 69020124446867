import React, { createContext, useContext, useState } from "react";

const ToggleModalFilterContext: any = createContext(false);

const ToggleModalFilterProvider = ({ children }) => {
   const [visible, setVisible]: any = useState(false);

   return (
      <ToggleModalFilterContext.Provider value={{ visible, setVisible }}>
         {children}
      </ToggleModalFilterContext.Provider>
   );
};

export default ToggleModalFilterProvider;

export const useModalFilterToggle = () => useContext(ToggleModalFilterContext);
