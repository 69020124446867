import { css } from "styled-components";

export const fontWeights = {
   regular: "400",
   medium: "500",
   semiBold: "600",
   bold: "700",
   extraBold: "800",
   black: "900",
};

export const fontFamily = css`
   @font-face {
      font-family: "Mark OT";
      src: url("/fonts/MarkOT-Thin.otf");
      font-weight: 200;
   }
   @font-face {
      font-family: "Mark OT";
      src: url("/fonts/MarkOT-ExtraLight.otf");
      font-weight: 300;
   }
   @font-face {
      font-family: "Mark OT";
      src: url("/fonts/MarkOT.ttf");
      font-weight: 400;
   }
   @font-face {
      font-family: "Mark OT";
      src: url("/fonts/MarkOT-Medium.ttf");
      font-weight: 500;
   }
   @font-face {
      font-family: "Mark OT";
      src: url("/fonts/MarkOT-Bold.otf");
      font-weight: 600;
   }
   @font-face {
      font-family: "Millik";
      src: url("/fonts/millik/Millik.ttf");
      font-weight: 700;
   }
`;
