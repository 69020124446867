import React, { createContext, useContext, useState } from "react";

const ToggleFilterContext: any = createContext(false);

const ToggleFilterProvider = ({ children }) => {
   const [active, setActive]: any = useState(false);

   return (
      <ToggleFilterContext.Provider value={{ active, setActive }}>
         {children}
      </ToggleFilterContext.Provider>
   );
};

export default ToggleFilterProvider;

export const useFilterToggle = () => useContext(ToggleFilterContext);
