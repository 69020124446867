const colors = {
   white: "var(--color-white)",
   black: "var(--color-black)",
   primary: "#264FFA",
   grey: "var(--color-grey)",
   grey01: "#fbfbfb",
   grey02: "#a7a7a7",
   transparent: "var(--color-transparent)",
   redError: "var(--color-red)",
   blue: "#173361",
   danger: "#D91935",
};

export { colors };
