import { useState, createContext, useContext } from "react";

export const UkFormContext = createContext({});

export default function UkFormProvider({ children }) {
   const [formData, setFormData] = useState({});

   const setFormValues = (values) => {
      setFormData((prevValues) => ({
         ...prevValues,
         ...values,
      }));
   };

   return (
      <UkFormContext.Provider value={{ formData, setFormValues, setFormData }}>
         {children}
      </UkFormContext.Provider>
   );
}

export const useUkFormData = () => useContext<any>(UkFormContext);
