import cookies from "js-cookie";

export const TOKEN = "-edusko-session-token-";
export const USER_DATA = "-edusko-session-user-";

export const saveToken = (token: string) => {
   cookies.set(TOKEN, token, { path: "/" });
};

export const getToken = () => {
   if (typeof window !== "undefined") {
      return cookies.get(TOKEN);
   }
};

export const clearToken = () => {
   return cookies.remove(TOKEN);
};

export const saveUserInfoToStorage = (payload: Record<string, string | number | undefined>) => {
   try {
      if (payload.token) {
         delete payload.token;
      }
      return cookies.set(USER_DATA, JSON.stringify(payload));
   } catch (e) {
      return e;
   }
};

export const getUserInfoFromStorage = () => {
   try {
      return JSON.parse(cookies.get(USER_DATA) as string);
   } catch (e) {
      return null;
   }
};

export const clearStorage = () => {
   try {
      cookies.remove(TOKEN);
      cookies.remove(USER_DATA);
   } catch (e) {
      return e;
   }
};
