import { useReducer, createContext, useContext } from "react";

interface FiltersProps {
   currentFilters: {
      country: string;
      state: string;
      category: string;
      price: string;
      type: string;
   };
}

const initialState = {
   currentFilters: {
      country: "",
      state: "",
      category: "",
      price: "",
      type: "",
   },
};

interface Action {
   type: "SET_CURRENT_FILTERS" | "CLEAR_FILTERS";
   payload?: Record<string, string>;
}

const setSchoolFilterAction = (payload) => ({
   type: "SET_CURRENT_FILTERS",
   payload,
});

const clearSchoolFilterAction = () => ({
   type: "CLEAR_FILTERS",
});

type filtersProviderProps = { children: React.ReactNode };

const FiltersContext = createContext<{
   state: any;
   dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

function schoolFiltersReducer(state: FiltersProps, action: Action) {
   switch (action.type) {
      case "SET_CURRENT_FILTERS": {
         return {
            ...state,
            currentFilters: {
               ...state.currentFilters,
               ...action.payload,
            },
         };
      }
      case "CLEAR_FILTERS":
         return {
            currentFilters: {},
         };
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function FiltersContextProvider({ children }: filtersProviderProps) {
   const [state, dispatch] = useReducer<any>(schoolFiltersReducer, initialState);

   return <FiltersContext.Provider value={{ state, dispatch }}>{children}</FiltersContext.Provider>;
}

const useFilters = () => {
   const {
      state: { currentFilters },
      dispatch,
   } = useContext(FiltersContext);

   const setSchoolFilter = (payload) => {
      dispatch(setSchoolFilterAction(payload));
   };

   const clearFilters = () => {
      dispatch(clearSchoolFilterAction());
   };

   return {
      setSchoolFilter,
      currentFilters,
      clearFilters,
   };
};

const FiltersConsumer = (Component) => {
   return class Authenticated extends Component {
      static async getInitialProps(ctx) {
         // Check if Page has a `getInitialProps`; if so, call it.
         const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
         // Return props.
         return { ...pageProps };
      }

      render() {
         return (
            <FiltersContext.Consumer>
               {(contexts) => <Component {...this.props} {...contexts} />}
            </FiltersContext.Consumer>
         );
      }
   };
};
export { FiltersContextProvider, useFilters, FiltersConsumer };
